export const CadK93 = (props) => {
  return props.formData[1]?.map((item, index) => (
    <>
      <div className="row" key={index}>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="col-12 row">
            <div className="col-6">
              <span className="col-form-label">Id Usuário</span>
            </div>
            <div className="col-6">
              <span className="col-form-label">Sistema</span>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_userid"
                value={item.k93_userid || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_system"
                value={item.k93_system || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
          </div>

          <div className="col-12 row">
            <div className="col-4">
              <span className="col-form-label">Cod Nível</span>
            </div>
            <div className="col-4">
              <span className="col-form-label">Nível anterior</span>
            </div>
            <div className="col-4">
              <span className="col-form-label">Nível Adm</span>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-4">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_codniv"
                value={item.k93_codniv || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
            <div className="col-4">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_nivant"
                value={item.k93_nivant || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
            <div className="col-4">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_admini"
                value={item.k93_admini || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="col-12 row">
            <div className="col-6">
              <span className="col-form-label">Data Expiração</span>
            </div>
            <div className="col-6">
              <span className="col-form-label">Data Expiração String</span>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <input
                type="date"
                className="form-control form-control-sm border border-dark"
                name="k93_datexp"
                value={item.k93_datexp?.slice(0, 10) || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
            <div className="col-6">
              <input
                type="date"
                className="form-control form-control-sm border border-dark"
                name="k93_sdatexp"
                value={item.k93_sdatexp?.slice(0, 10) || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
          </div>

          <div className="col-12 row">
            <div className="col-6">
              <span className="col-form-label">Hora Limite</span>
            </div>
            <div className="col-6">
              <span className="col-form-label">Hora Limite String</span>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-6">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_horlim"
                value={item.k93_horlim || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="k93_shorlim"
                value={item.k93_shorlim || ''}
                onChange={props.handleInput}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <hr />
    </>
  ));
};
