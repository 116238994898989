import { useState } from 'react';

import { CadK92 } from './CadK92';
import { CadK93 } from './CadK93';

export const Cadastro = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData([
      { ...props.formData[0], [name]: value },
      props.formData[1]
    ]);
  };

  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            activeTab === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(1)}
          style={{ fontSize: props.tamTexto }}
        >
          K92
        </p>
        <p
          className={`nav-link ${
            activeTab === 2 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(2)}
          style={{ fontSize: props.tamTexto }}
        >
          K93
        </p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
          id="tabCadastro1"
        >
          <CadK92
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>

        <div
          className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
          id="tabCadastro2"
        >
          <CadK93
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
      </div>
    </>
  );
};
